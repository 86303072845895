<template>
  <div>
    <TransitionRoot as="template" :show="markerEditModalOpen">
      <Dialog
        as="div"
        class="fixed inset-0 z-20"
        :class="[isLoadingData ? 'overflow-hidden' : 'overflow-y-auto']"
        @close="!loading && closeEditModal()"
      >
        <div class="flex justify-center min-h-screen text-center sm:block">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
            />
          </TransitionChild>

          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="relative inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white shadow-xl sm:rounded-lg sm:my-8 sm:align-middle sm:max-w-sm sm:p-6 md:max-w-4xl"
            >
              <div
                class="fixed top-0 bottom-0 left-0 right-0 z-40 w-screen h-screen md:w-auto md:h-auto md:absolute"
                v-if="isLoadingData"
              >
                <div class="absolute inset-0 bg-white opacity-70"></div>
                <div
                  class="relative z-10 flex items-center justify-center h-full gap-3"
                >
                  <svg
                    class="w-5 h-5 mr-3 -ml-1 text-primary animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span class="text-gray-500">Laden</span>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <form
                  action="#"
                  @submit.prevent="submit"
                  :class="{ 'pointer-events-none': loading }"
                >
                  <div>
                    <div class="space-y-6 bg-white sm:p-6">
                      <h3>Basisinformatie</h3>
                      <div
                        class="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6"
                      >
                        <div class="sm:col-span-6">
                          {{ formError.code }}
                          <label
                            for="qrCode"
                            class="block text-sm font-medium text-gray-700"
                            >QR code</label
                          >
                          <div class="flex mt-1 rounded-md shadow-sm">
                            <div
                              class="relative flex items-stretch flex-grow shadow-sm focus-within:z-10"
                            >
                              <input
                                type="text"
                                name="qrCode"
                                id="qrCode"
                                v-model="formData.qrCode"
                                :required="selected.id == 2"
                                class="block w-full pr-10 border shadow-sm appearance-none sm:text-sm focus:outline-none rounded-l-md"
                                :class="
                                  formError
                                    ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                                    : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                                "
                                placeholder=""
                                :aria-invalid="formError"
                                aria-describedby="qrCode-error"
                              />
                              <div
                                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                                v-if="formError"
                              >
                                <svg
                                  class="w-5 h-5 text-red-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                            </div>
                            <button
                              @click="QRScanModalOpen = true"
                              type="button"
                              title="Scan QR/Lin-code"
                              aria-label="Scan QR/Lin-code"
                              class="relative inline-flex items-center px-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary disabled:cursor-not-allowed"
                            >
                              <QrcodeIcon
                                class="w-6 h-6 text-gray-400"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <!-- <div class="sm:col-span-6">
                          <label
                            class="block text-sm font-medium text-gray-700"
                          >
                            Beschrijving
                          </label>
                          <div class="mt-1">
                            <input
                              type="text"
                              name="description"
                              id="description"
                              v-model="formData.description"
                              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                              placeholder="Wordt niet overgenomen in het logboek"
                            />
                          </div>
                        </div> -->
                        <div class="sm:col-span-3">
                          <label
                            for="external"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Werkzaamheden uitgevoerd door externe partij
                          </label>
                          <div class="mt-1">
                            <input
                              type="checkbox"
                              v-model="formData.isExternal"
                              name="external"
                              class="block border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                            />
                          </div>
                        </div>
                        <div class="sm:col-span-3">
                          <label
                            for="repair"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Betreft reparatie
                          </label>
                          <div class="mt-1">
                            <input
                              type="checkbox"
                              v-model="formData.isRepair"
                              name="repair"
                              class="block border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                            />
                          </div>
                        </div>

                        <div class="sm:col-span-3">
                          <label
                            for="placement"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Positie
                          </label>
                          <div class="mt-1">
                            <select
                              v-model="formData.placement"
                              id="placement"
                              name="placement"
                              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                              :required="selected.id == 2"
                            >
                              placements
                              <option value="" disabled hidden>
                                Selecteer een optie
                              </option>
                              <option v-for="(placement, index) of placements">
                                {{ placement }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="p-4 mt-3 rounded-md bg-yellow-50"
                            v-if="
                              formData.placement &&
                              !placements.find(
                                (val) => val === formData.placement
                              )
                            "
                          >
                            <div class="flex">
                              <div class="flex-shrink-0">
                                <!-- Heroicon name: solid/exclamation -->
                                <svg
                                  class="w-5 h-5 text-yellow-400"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div class="ml-3">
                                <h3 class="text-sm font-medium text-yellow-800">
                                  Let op!
                                </h3>
                                <div class="mt-2 text-sm text-yellow-700">
                                  <p>
                                    De opgeslagen positie '{{
                                      formData.placement
                                    }}' bestaat niet meer, maar is wel
                                    toegevoegd aan deze spot. Als er een nieuwe
                                    positie wordt geselecteerd, zal de oude
                                    positie permanent van deze spot verwijderd
                                    worden.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="sm:col-span-3">
                          <label
                            for="room"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Ruimte
                          </label>
                          <div class="mt-1">
                            <select
                              v-model="formData.room"
                              id="room"
                              name="room"
                              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                              :required="selected.id == 2"
                            >
                              <option value="" disabled hidden>
                                Selecteer een optie
                              </option>
                              <option v-for="(room, index) of rooms">
                                {{ room }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="p-4 mt-3 rounded-md bg-yellow-50"
                            v-if="
                              formData.room &&
                              !rooms.find((val) => val === formData.room)
                            "
                          >
                            <div class="flex">
                              <div class="flex-shrink-0">
                                <!-- Heroicon name: solid/exclamation -->
                                <svg
                                  class="w-5 h-5 text-yellow-400"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div class="ml-3">
                                <h3 class="text-sm font-medium text-yellow-800">
                                  Let op!
                                </h3>
                                <div class="mt-2 text-sm text-yellow-700">
                                  <p>
                                    De opgeslagen ruimte '{{ formData.room }}'
                                    bestaat niet meer, maar is wel toegevoegd
                                    aan deze spot. Als er een nieuwe ruimte
                                    wordt geselecteerd, zal de oude ruimte
                                    permanent van deze spot verwijderd worden.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="sm:col-span-3">
                          <label
                            for="position"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Nummer
                          </label>
                          <input
                            type="text"
                            name="position"
                            id="position"
                            v-model="formData.position"
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                            placeholder="Nummer"
                          />
                        </div>
                        <div class="sm:col-span-3">
                          <label
                            for="fireResistantRequirement"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Brandwerende eis
                          </label>
                          <div class="mt-1">
                            <select
                              v-model="formData.fireResistantRequirement"
                              id="fireResistantRequirement"
                              name="fireResistantRequirement"
                              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                              :required="selected.id == 2"
                            >
                              <option value="" disabled hidden>
                                Selecteer het aantal minuten
                              </option>
                              <option>20 minuten</option>
                              <option>30 minuten</option>
                              <option>60 minuten</option>
                              <option>90 minuten</option>
                              <option>120 minuten</option>
                              <option>150 minuten</option>
                              <option>180 minuten</option>
                              <option>240 minuten</option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="sm:col-span-3"
                          v-if="
                            role === 'OWNER' && connectionStatusStore.online
                          "
                        >
                          <label
                            for="fireResistantRequirement"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Etage
                          </label>
                          <div class="mt-1">
                            <select
                              v-model="formData.floorID"
                              id="fireResistantRequirement"
                              name="fireResistantRequirement"
                              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                              required
                            >
                              <option value="" disabled hidden>
                                Selecteer een etage
                              </option>
                              <option
                                v-for="floor of allFloors"
                                :value="floor.id"
                              >
                                {{ floor.data().floorName }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <Listbox
                          as="div"
                          v-model="selected"
                          :class="[
                            role === 'OWNER'
                              ? 'sm:col-span-3'
                              : 'sm:col-span-6',
                          ]"
                          required
                        >
                          <ListboxLabel
                            class="block text-sm font-medium text-gray-700"
                          >
                            Status
                          </ListboxLabel>
                          <div class="relative mt-1">
                            <ListboxButton
                              class="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
                            >
                              <div class="flex items-center">
                                <span
                                  :class="[
                                    selected.color,
                                    'flex-shrink-0 inline-block h-2 w-2 rounded-full',
                                  ]"
                                />
                                <span class="block ml-3 truncate">{{
                                  selected.name
                                }}</span>
                              </div>
                              <span
                                class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                              >
                                <SelectorIcon
                                  class="w-5 h-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </ListboxButton>

                            <transition
                              leave-active-class="transition duration-100 ease-in"
                              leave-from-class="opacity-100"
                              leave-to-class="opacity-0"
                            >
                              <ListboxOptions
                                class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                              >
                                <template
                                  v-for="status in MarkerStatuses"
                                  :key="status.id"
                                >
                                  <ListboxOption
                                    as="template"
                                    :value="status"
                                    v-slot="{ active, selected }"
                                    v-if="status.id !== 5 && status.id !== 6"
                                  >
                                    <li
                                      :class="[
                                        active
                                          ? 'text-secondary bg-primary'
                                          : 'text-secondary',
                                        'cursor-default select-none relative py-2 pl-3 pr-9',
                                      ]"
                                    >
                                      <div class="flex items-center">
                                        <span
                                          :class="[
                                            status.color,
                                            'flex-shrink-0 inline-block h-3 w-3 rounded-md',
                                          ]"
                                          aria-hidden="true"
                                        />
                                        <span
                                          :class="[
                                            selected
                                              ? 'font-semibold'
                                              : 'font-normal',
                                            'ml-3 block truncate',
                                          ]"
                                        >
                                          {{ status.name }}
                                        </span>
                                      </div>

                                      <span
                                        v-if="selected"
                                        :class="[
                                          active
                                            ? 'text-white'
                                            : 'text-primary',
                                          'absolute inset-y-0 right-0 flex items-center pr-4',
                                        ]"
                                      >
                                        <CheckIcon
                                          class="w-5 h-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </li>
                                  </ListboxOption>
                                </template>
                              </ListboxOptions>
                            </transition>
                          </div>
                        </Listbox>
                        <div class="sm:col-span-3">
                          <label
                            for="category"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Categorie
                          </label>
                          <div class="mt-1">
                            <select
                              v-model="formData.category"
                              id="category"
                              name="category"
                              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                            >
                              <option
                                v-for="category in categories"
                                :key="category.id"
                                :value="category.id"
                              >
                                {{ category.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <h3>Componenten</h3>
                      <!-- componenten headers -->
                      <!-- <div
                        class="grid grid-cols-12 gap-4"
                        v-if="formData.addedComponents.length > 0"
                      >
                        <div class="sm:col-span-1"></div>
                        <div class="col-span-3 md:col-span-4">
                          <label class="block text-sm font-medium text-gray-700"
                            >Component</label
                          >
                        </div>
                        <div class="col-span-3 md:col-span-3">
                          <label class="block text-sm font-medium text-gray-700"
                            >Type</label
                          >
                        </div>
                        <div class="col-span-2 md:col-span-2">
                          <label class="block text-sm font-medium text-gray-700"
                            >Hoeveelheid</label
                          >
                        </div>
                        <div class="col-span-1">
                          <label class="block text-sm font-medium text-gray-700"
                            >Isolatie</label
                          >
                        </div>
                      </div> -->
                      <!-- Toegevoegde componenten met een match (addedComponents) -->
                      <div
                        class="grid grid-cols-12 gap-4"
                        v-for="(component, index) in formData.addedComponents"
                        :key="index"
                        :value="component"
                      >
                        <div class="relative col-span-1">
                          <button
                            type="button"
                            class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                            @click="removeAddedComponent(index, false)"
                          >
                            <MinusIcon
                              class="w-6 h-6 text-white transition-all"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                        <div class="col-span-6 md:col-span-3">
                          <select
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
                            v-model="component.componentID"
                          >
                            <option value="" disabled selected>
                              Component
                            </option>
                            <option
                              v-for="(
                                availableComponent, index
                              ) in activeComponents"
                              :key="index"
                              :value="availableComponent.componentID"
                            >
                              {{ availableComponent.componentName }}
                            </option>
                          </select>
                        </div>
                        <div class="col-span-5 md:col-span-2">
                          <select
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
                            :required="component.componentID"
                            :disabled="!component.componentID"
                            v-model="component.typeID"
                          >
                            <option value="" disabled selected>Type</option>
                            <option
                              v-for="(type, index) in activeComponents[
                                activeComponents.findIndex(
                                  (item) =>
                                    item.componentID === component.componentID
                                )
                              ].types"
                              :key="index"
                              :value="type.typeID"
                            >
                              {{ type.typeName }}
                            </option>
                          </select>
                        </div>
                        <div class="col-span-6 col-start-2 md:col-span-2">
                          <select
                            v-model="component.insulation"
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
                            :disabled="!component.typeID"
                            :required="component.typeID"
                          >
                            <option value="" disabled selected>Isolatie</option>
                            <option>Geen</option>
                            <option>Armaflex</option>
                            <option>Isogenopak</option>
                            <option>PIR</option>
                            <option>Wol</option>
                          </select>
                        </div>

                        <div class="col-span-5  md:col-span-2">
                          <select
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
                            :required="component.selectedAmount"
                            :disabled="!component.selectedAmount"
                            v-model="component.selectedAmount"
                          >
                            <option value="" disabled selected>Aantal</option>
                            <option
                              v-for="amount in 200"
                              :key="amount"
                              :value="amount"
                            >
                              {{ amount }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- Alert in het geval er 1 of meer toegevoegde componenten zijn die geen match hebben (addedDeletedComponents) -->
                      <div
                        class="p-4 rounded-md bg-yellow-50"
                        v-if="formData.addedDeletedComponents.length > 0"
                      >
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <!-- Heroicon name: solid/exclamation -->
                            <svg
                              class="w-5 h-5 text-yellow-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-medium text-yellow-800">
                              Let op!
                            </h3>
                            <div class="mt-2 text-sm text-yellow-700">
                              <p>
                                De onderstaande componenten bestaan niet meer
                                maar zijn wel toegevoegd aan deze spot. Als een
                                van deze componenten verwijdert is zal deze
                                permanent van deze spot verwijderd worden nadat
                                de spot is opgeslagen.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Toegevoegde componenten zonder een match (addedDeletedComponents) -->
                      <div
                        class="grid grid-cols-12 gap-4"
                        v-for="(
                          component, index
                        ) in formData.addedDeletedComponents"
                        :key="index"
                        :value="component"
                      >
                        <div class="relative col-span-1">
                          <button
                            type="button"
                            class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                            @click="removeAddedComponent(index, true)"
                          >
                            <MinusIcon
                              class="w-6 h-6 text-white transition-all"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                        <div class="col-span-6 md:col-span-3">
                          <p>Component naam niet meer beschikbaar</p>
                        </div>
                        <div class="col-span-5 md:col-span-2">
                          <p>Type naam niet meer beschikbaar</p>
                        </div>
                        <div class="col-span-6 col-start-2 md:col-span-2">
                          <select
                            v-model="component.insulation"
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
                            required
                          >
                            <option value="" disabled selected>Isolatie</option>
                            <option>Geen</option>
                            <option>Armaflex</option>
                            <option>Isogenopak</option>
                            <option>PIR</option>
                            <option>Wol</option>
                          </select>
                        </div>

                        <div class="col-span-6 col-start-2 md:col-span-2">
                          <select
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
                            v-model="component.selectedAmount"
                            :disabled="!component.insulation"
                            required
                          >
                            <option value="" disabled selected>Aantal</option>
                            <option
                              v-for="amount in 200"
                              :key="amount"
                              :value="amount"
                            >
                              {{ amount }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- Functionaliteit voor het toevoegen van een nieuw component -->

                      <h6>Nieuw component</h6>
                      <!-- componenten headers -->
                      <div class="grid grid-cols-12 gap-4">
                        <div class="hidden md:block md:col-span-4">
                          <label class="block text-sm font-medium text-gray-700"
                            >Component</label
                          >
                        </div>
                        <div class="hidden md:block md:col-span-2">
                          <label class="block text-sm font-medium text-gray-700"
                            >Type</label
                          >
                        </div>
                        <div class="hidden md:block md:col-span-2">
                          <label class="block text-sm font-medium text-gray-700"
                            >Isolatie</label
                          >
                        </div>

                        <div class="hidden md:block md:col-span-2">
                          <label class="block text-sm font-medium text-gray-700"
                            >Aantal</label
                          >
                        </div>
                      </div>
                      <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-6 md:col-span-4">
                          <select
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
                            v-model="formData.componentToAdd.selectedComponent"
                            @change="clearAddNewComponentType"
                          >
                            <option value="" disabled selected>
                              Component
                            </option>
                            <option
                              v-for="(
                                availableComponent, index
                              ) in activeComponents"
                              :key="index"
                              :value="availableComponent"
                            >
                              {{ availableComponent.componentName }}
                            </option>
                          </select>
                        </div>
                        <div class="col-span-6 md:col-span-2">
                          <Combobox
                            as="div"
                            v-model="formData.componentToAdd.selectedType"
                            :disabled="
                              !formData.componentToAdd.selectedComponent
                                .componentID || loadingTypeSave
                            "
                            v-slot="{ open }"
                          >
                            <div class="relative">
                              <ComboboxInput
                                class="w-full rounded-md border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                @change="typeFilter = $event.target.value"
                                :displayValue="(type) => type.typeName"
                                placeholder="Type"
                                @click="
                                  () => {
                                    if (!open) {
                                      buttonRef.el.click();
                                    }
                                  }
                                "
                                @focus="
                                  () => {
                                    typeFilter = '';
                                    buttonRef.current?.click();
                                  }
                                "
                              />

                              <ComboboxButton
                                ref="buttonRef"
                                class="hidden inset-y-0 right-0 items-center rounded-r-md px-2 focus:outline-none"
                              >
                                <ChevronDownIcon
                                  class="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </ComboboxButton>
                              <ComboboxOptions
                                class="absolute z-10 mt-1 w-full md:w-[200%] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                              >
                                <ComboboxOption
                                  v-for="(type, index) in filteredTypes"
                                  :key="index"
                                  :value="type"
                                  as="template"
                                  v-slot="{ active, selected }"
                                >
                                  <li
                                    :class="[
                                      'relative cursor-default select-none py-2 px-3',
                                      active
                                        ? 'bg-indigo-600 text-white'
                                        : 'text-gray-900',
                                    ]"
                                  >
                                    <div class="flex items-center">
                                      <span
                                        :class="[selected && 'font-semibold']"
                                      >
                                        {{ type.typeName }}
                                      </span>
                                    </div>
                                  </li>
                                </ComboboxOption>

                                <ComboboxOption
                                  as="template"
                                  v-slot="{ active, selected }"
                                  key="index"
                                  v-if="typeFilter"
                                  :value="typeFilter"
                                >
                                  <li>
                                    <button
                                      :disabled="loadingTypeSave"
                                      class="relative disabled:opacity-80 flex flex-row gap-x-2 cursor-default select-none py-2 pl-3 w-full whitespace-nowrap overflow-hidden"
                                      :class="[
                                        active
                                          ? 'bg-indigo-600 text-white'
                                          : 'text-gray-900',
                                      ]"
                                      type="button"
                                      v-on:click.stop="addType()"
                                    >
                                      <svg
                                        v-if="loadingTypeSave"
                                        class="w-5 h-5 mr-3 -ml-1 text-secondary animate-spin"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <circle
                                          class="opacity-25"
                                          cx="12"
                                          cy="12"
                                          r="10"
                                          stroke="currentColor"
                                          stroke-width="4"
                                        ></circle>
                                        <path
                                          class="opacity-75"
                                          fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                      </svg>
                                      <PlusIcon
                                        v-else
                                        class="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />

                                      <span> Voeg "{{ typeFilter }}" toe </span>
                                    </button>
                                  </li>
                                </ComboboxOption>
                              </ComboboxOptions>
                            </div>
                          </Combobox>
                          <!-- <select
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
                            :disabled="
                              !formData.componentToAdd.selectedComponent
                                .componentID
                            "
                            v-model="formData.componentToAdd.selectedType"
                          >
                            <option value="" disabled selected>Type</option>
                            <option
                              v-for="(type, index) in formData.componentToAdd
                                .selectedComponent.types"
                              :key="index"
                              :value="type"
                            >
                              {{ type.typeName }}
                            </option>
                          </select> -->
                        </div>
                        <div class="col-span-6 md:col-span-2">
                          <select
                            v-model="formData.componentToAdd.insulation"
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
                            :disabled="
                              !formData.componentToAdd.selectedType.typeID
                            "
                          >
                            <option value="" disabled selected>Isolatie</option>
                            <option>Geen</option>
                            <option>Armaflex</option>
                            <option>Isogenopak</option>
                            <option>PIR</option>
                            <option>Wol</option>
                          </select>
                        </div>

                        <div class="col-span-6 md:col-span-2">
                          <select
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
                            :disabled="!formData.componentToAdd.selectedType.typeID"
                            v-model="formData.componentToAdd.selectedAmount"
                            @change="addNewComponentType()"
                          >
                            <option value="" disabled selected>Aantal</option>
                            <option
                              v-for="amount in 200"
                              :key="amount"
                              :value="amount"
                            >
                              {{ amount }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <h3>Gebruikte materialen</h3>

                      <div
                        class="grid grid-cols-12 gap-y-6 gap-x-4 sm:grid-cols-6"
                      >
                        <div
                          v-for="(material, index) in formData.materials"
                          :key="index"
                          class="col-span-6 sm:col-span-3"
                        >
                          <div class="relative flex items-start">
                            <div class="flex items-center h-5">
                              <input
                                :id="`${material.data.id}`"
                                :name="`${material.data.id}`"
                                type="checkbox"
                                v-model="formData.materials[index].selected"
                                @change="
                                  materialOnChange(formData.materials[index])
                                "
                                class="w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary"
                              />
                            </div>
                            <div class="ml-3 text-sm">
                              <label
                                :for="`${material.data.id}`"
                                class="font-medium text-gray-700"
                                >{{ material.data.data().materialName }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <h3>Media</h3>
                      <div
                        class="grid grid-cols-12 mt-6 gap-y-6 gap-x-4 sm:grid-cols-9"
                      >
                        <div
                          v-for="(image, index) in formData.images"
                          :key="index"
                          class="col-span-6 sm:col-span-3"
                        >
                          <div
                            class="relative flex flex-col justify-center w-full h-full rounded-md"
                          >
                            <div class="relative w-full h-full">
                              <div
                                class="absolute flex bottom-2 right-2 gap-x-2"
                              >
                                <button
                                  type="button"
                                  v-on:click="deleteImage(index, image)"
                                  class="flex items-center p-1 text-white transition-all bg-red-600 hover:bg-red-700 right-1 top-2 btn"
                                  title="Verwijder afbeelding"
                                >
                                  <TrashIcon
                                    class="w-5 h-5 text-white"
                                    aria-hidden="true"
                                  />
                                </button>

                                <button
                                  @click="openCamera(index)"
                                  type="button"
                                  class="flex cursor-pointer items-center p-1 text-white transition-all bg-[#E5BF00] hover:bg-[#AD9000] right-1 top-2 btn"
                                  title="Vervang afbeelding"
                                >
                                  <svg
                                    id="Layer_3"
                                    data-name="Layer 3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    class="w-5 h-5 text-black"
                                  >
                                    <path
                                      d="M17.5,21.5,16,20l1.5-1.5"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    />
                                    <path
                                      d="M16,20h2a3,3,0,0,0,3-3"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    />
                                    <path
                                      d="M5,21h6a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2H5a2,2,0,0,0-2,2v7A2,2,0,0,0,5,21Z"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    />
                                    <path
                                      d="M16.235,14H19a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2H13a2,2,0,0,0-2,2V7"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    />
                                    <path
                                      d="M6.5,2.5,8,4,6.5,5.5"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    />
                                    <path
                                      d="M8,4H6A3,3,0,0,0,3,7"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    />
                                    <path d="M0,0H24V24H0Z" fill="none" />
                                  </svg>
                                </button>

                                <button
                                  type="button"
                                  v-on:click="rotateImage(index, image.id)"
                                  class="flex items-center p-1 text-white transition-all bg-[#102b42] hover:bg-[#050E15] right-1 top-2 btn"
                                  title="Draai afbeelding 90°"
                                >
                                  <svg
                                    class="w-5 h-5 text-white"
                                    version="1.1"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                  >
                                    <g
                                      stroke-linecap="round"
                                      stroke-width="2"
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        d="M12 21h-7c-1.105 0-2-.895-2-2v-7c0-1.105.895-2 2-2h7c1.105 0 2 .895 2 2v7c0 1.105-.895 2-2 2Z"
                                      ></path>
                                      <polyline
                                        points="23,10 20,13 17,10"
                                      ></polyline>
                                      <path
                                        d="M20 13v-6c0-2.209-1.791-4-4-4h-4"
                                      ></path>
                                    </g>
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="none"
                                    ></rect>
                                  </svg>
                                </button>
                              </div>
                              <img
                                v-if="
                                  connectionStatusStore.online &&
                                  image.imageDownloadURL
                                "
                                v-bind:src="image.imageDownloadURL"
                                class="aspect-[16/12] h-full w-full object-cover cursor-pointer rounded-t-md"
                                :class="[
                                  index !== formData.images.length - 1 &&
                                    index !== formData.images.length - 2 &&
                                    'rounded-b-md',
                                ]"
                                @click="openImageModal(image.imageDownloadURL)"
                              />
                              <img
                                v-else-if="
                                  formData.imagesReplacePending.find(
                                    (replaceImage) =>
                                      replaceImage.id === image.id
                                  ) !== undefined
                                "
                                v-bind:src="
                                  formData.imagesReplacePending.find(
                                    (replaceImage) =>
                                      replaceImage.id === image.id
                                  ).imageSrc
                                "
                                class="rounded-t-md aspect-[16/12] object-cover h-full w-full"
                                :class="[
                                  index !== formData.images.length - 1 &&
                                    index !== formData.images.length - 2 &&
                                    'rounded-b-md',
                                ]"
                              />
                              <img
                                v-else-if="
                                  localImages.find(
                                    (localImage) => localImage.id === image.id
                                  ) !== undefined
                                "
                                v-bind:src="
                                  localImages.find(
                                    (localImage) => localImage.id === image.id
                                  ).base64
                                "
                                class="rounded-t-md aspect-[16/12] object-cover h-full w-full"
                                :class="[
                                  index !== formData.images.length - 1 &&
                                    index !== formData.images.length - 2 &&
                                    'rounded-b-md',
                                ]"
                              />
                              <div
                                v-else
                                class="flex justify-center w-full h-full px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-t-md"
                                :class="[
                                  index !== formData.images.length - 1 &&
                                    index !== formData.images.length - 2 &&
                                    'rounded-b-md',
                                ]"
                              >
                                <div class="space-y-1 text-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="w-12 h-12 mx-auto text-yellow-400"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    stroke="currentColor"
                                    stroke-width="2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                    />
                                  </svg>

                                  <div
                                    class="flex justify-center py-2 text-sm text-gray-600"
                                  >
                                    <p v-if="connectionStatusStore.online">
                                       Deze afbeelding zal worden weergegeven
                                      zodra hij is geüpload door de monteur.
                                    </p>
                                    <p v-else>
                                       Deze afbeelding is niet gedownload en zal
                                      pas worden weergegeven zodra je weer
                                      online bent.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <template v-if="formData.images.length > 1">
                              <div
                                v-if="
                                  index === 0 ||
                                  index === formData.images.length - 1
                                "
                                class="w-full bg-[#E5BF00] text-center rounded-b-md py-1"
                              >
                                {{
                                  index === formData.images.length - 1
                                    ? 'Na-registratie'
                                    : 'Voor-registratie'
                                }}
                              </div>
                            </template>
                            <template v-else>
                              <div
                                v-if="index === formData.images.length - 1"
                                class="w-full bg-[#E5BF00] text-center rounded-b-md py-1"
                              >
                                Voor-registratie
                              </div>
                            </template>
                          </div>
                        </div>

                        <div
                          v-for="(image, index) in formData.imagesPending"
                          :key="index"
                          class="col-span-6 sm:col-span-3"
                        >
                          <div
                            class="relative flex justify-center w-full h-full rounded-md"
                          >
                            <img
                              v-bind:src="image.imageSrc"
                              class="rounded-md aspect-[16/12] opacity-50 object-cover"
                            />
                            <button
                              type="button"
                              v-on:click="removeImagePending(index)"
                              class="absolute right-1 top-2"
                            >
                              <XIcon
                                class="w-6 h-6 mr-2 text-white transition-all hover:text-red-600"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div class="col-span-12 md:col-span-3">
                          <div
                            @dragover="dragover"
                            @dragleave="dragleave"
                            @drop="drop($event, undefined)"
                            class="flex justify-center w-full h-full p-4 mt-1 border-2 border-gray-300 border-dashed rounded-md"
                          >
                            <div
                              class="flex items-center space-y-1 text-center divide-x divide-gray-300"
                            >
                              <div class="flex-1">
                                <div>
                                  <label
                                    for="imageFile"
                                    class="relative cursor-pointer"
                                  >
                                    <svg
                                      class="w-12 h-12 mx-auto mb-3 text-gray-300"
                                      stroke="currentColor"
                                      fill="none"
                                      viewBox="0 0 48 48"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </svg>
                                    <div>
                                      <span
                                        class="block text-sm font-bold text-black underline"
                                        >Klik hier om te uploaden</span
                                      >
                                      <span class="text-xs"
                                        >of sleep een afbeelding naar dit vak
                                      </span>
                                    </div>
                                    <input
                                      @change="onFileChange($event, undefined)"
                                      id="imageFile"
                                      name="image"
                                      type="file"
                                      class="sr-only"
                                    />
                                  </label>
                                </div>

                                <div
                                  class="relative flex items-center justify-center my-3 before:h-px before:w-full before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-gray-300"
                                >
                                  <div
                                    class="relative z-10 px-2 text-sm text-gray-300 bg-white"
                                  >
                                    OF
                                  </div>
                                </div>

                                <div>
                                  <button
                                    @click="openCamera(undefined)"
                                    type="button"
                                    class="flex items-center w-full space-x-3 btn btn--primary sm:w-auto mx-auto"
                                  >
                                    <CameraIcon class="w-5 text-black" />
                                    <span>Maak een foto</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h3>Opmerkingen</h3>

                      <div
                        class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                      >
                        <div class="sm:col-span-6">
                          <label
                            for="adress"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Toelichting
                          </label>
                          <div class="mt-1">
                            <input
                              type="text"
                              name="remarks"
                              id="remarks"
                              v-model="formData.remarks"
                              class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div v-if="formError" class="p-4 rounded-md bg-red-50">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <svg
                              class="w-5 h-5 text-red-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                          <div class="ml-3">
                            <h3 class="mb-0 text-sm font-medium text-red-800">
                              {{ formError }}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="px-4 py-3 text-righ sm:px-6">
                      <div class="flex justify-end space-x-3">
                        <button
                          type="button"
                          @click="closeEditModal"
                          class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                          Annuleren
                        </button>
                        <Button type="submit" :loading="loading">
                          <template #text>Opslaan</template>
                          <template #loading-text>Verwerken...</template>
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
                <ConfirmationModal
                  :open="confirmModalData.open"
                  :title="confirmModalData.title"
                  :body="confirmModalData.body"
                  :cancelButtonText="confirmModalData.cancelButtonText"
                  :confirmButtonText="confirmModalData.confirmButtonText"
                  @cancel="onCancelDelete"
                  @confirm="onConfirmDelete"
                />
              </div>
            </div>
          </TransitionChild>
        </div>

        <CameraModal
          :open="cameraModalOpen"
          :index="selectedImageIndex"
          @close-modal="closeCameraModal"
          @on-snapshot="handleSnapshot"
        />

        <QRScanModal
          :open="QRScanModalOpen"
          @scan-complete="QRScanComplete"
          @close-modal="QRScanModalOpen = false"
        />

        <ImageModal
          :open="imageModalOpen"
          @close-image-modal="imageModalOpen = false"
          :image-src="imageModalCurrentSrc"
        />
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup>
  import { ref, watch, computed, nextTick } from 'vue';
  import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    ComboboxButton,
  } from '@headlessui/vue';
  import {
    CheckIcon,
    SelectorIcon,
    TrashIcon,
    XIcon,
    MinusIcon,
    QrcodeIcon,
    CameraIcon,
    ChevronDownIcon,
    PlusIcon,
  } from '@heroicons/vue/outline';
  import { db, storage } from '@/firebase/firebase.js';
  import Button from '../../components/Button.vue';
  import {
    collection,
    addDoc,
    updateDoc,
    doc,
    getDocs,
    query,
    orderBy,
    increment,
    deleteDoc,
    setDoc,
  } from 'firebase/firestore';
  import {
    getDownloadURL,
    ref as firebaseRef,
    uploadBytesResumable,
    deleteObject,
  } from 'firebase/storage';
  import moment from 'moment';
  import { getAuth } from 'firebase/auth';
  import { v4 as uuidv4 } from 'uuid';
  import { MarkerStatuses } from '@/components/Map/MarkerStatus';
  import { Categories as categories } from '@/components/Marker/Category.js';
  import ConfirmationModal from '@/components/ConfirmationModal.vue';
  import QRScanModal from '@/components/QR/QRScanModal.vue';
  import CameraModal from '@/components/Camera/CameraModal.vue';
  import ImageModal from '@/components/ImageModal.vue';
  import { useMarkersStore } from '@/stores/markers-store';
  import { useConnectionStatusStore } from '@/stores/connectionStatus-store';
  import { storeToRefs } from 'pinia';
  import Compress from 'compress.js';
  import { useDynamicDataStore } from '@/stores/dynamic-data-store';

  const { localImages } = storeToRefs(useMarkersStore());
  const {
    allComponents,
    activeComponents,
    allMaterials,
    isLoadingDyanmicData,
  } = storeToRefs(useDynamicDataStore());
  const { setImagesByProjectID } = useMarkersStore();
  const connectionStatusStore = useConnectionStatusStore();

  const props = defineProps({
    markerEditModalOpen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    marker: {
      type: Object,
      required: true,
      default: () => {},
    },
    projectID: {
      type: String,
      required: true,
      default: () => '',
    },
    floorID: {
      type: String,
      required: true,
      default: () => '',
    },
  });

  setImagesByProjectID(props.projectID);

  //confirmModal
  const confirmModalData = ref({
    open: false,
    title: 'Materiaal verwijderen',
    body: 'Weet je zeker dat je dit materiaal wilt verwijderen? Alle gegevens van dit materiaal worden voor altijd permanent van onze servers verwijderd. Deze actie kan niet ongedaan worden gemaakt.',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Bevestigen',
  });

  const currentMaterialConfirmation = ref();
  const formError = ref('');
  const selected = ref({
    id: 0,
    name: '',
    color: '',
    markerIcon: '',
  });
  const currentStatus = ref();
  const loading = ref(false);
  const loadingTypeSave = ref(false);

  const isLoadingData = ref(false);
  const user = ref();
  const role = ref();
  const QRScanModalOpen = ref(false);
  const cameraModalOpen = ref(false);
  const imageModalOpen = ref(false);
  const imageModalCurrentSrc = ref('');

  //all firebaseCollection references (the references that need a markerID to be made are made in the createFireStoreReferences() method)
  const markerMaterialsCollection = ref();
  const imagesCollection = ref();
  const markerComponentsCollection = ref();
  const markerRef = ref();
  const historyCollection = ref();
  const floorsCollection = ref(
    collection(db, 'projects', props.projectID, 'floors')
  );

  const typeFilter = ref('');
  const buttonRef = ref();

  const selectedImageIndex = ref(undefined);

  const filteredTypes = computed(() =>
    typeFilter.value === ''
      ? formData.value.componentToAdd.selectedComponent.types
      : formData.value.componentToAdd.selectedComponent.types.filter((type) => {
          return type.typeName
            .toLowerCase()
            .includes(typeFilter.value.toLowerCase());
        })
  );

  const compress = ref(new Compress());
  const allFloors = ref([]);

  const emit = defineEmits(['closeEditModal']);

  const placements = ref([
    'Kozijn',
    'Muur',
    'Plafond',
    'Schachtwand',
    'Vloer',
    'N.v.t.',
  ]);

  const rooms = ref([
    'Badkamer/toilet',
    'Berging',
    'Cv-ruimte',
    'Gang',
    'Garage',
    'Kamer',
    'Keuken',
    'Ketelhuis',
    'Kantoorruimte',
    'Magazijn/Loods',
    'Meterkast',
    'Technische ruimte',
    'Trappenhuis',
    'Werkplaats',
    'Woning',
    'Overig',
  ]);

  //NOTE: We renamed some labels but these still correspond to the old data fields
  //Room belongs to the label Ruimte
  //Position belongs to the label Nummer
  //Placement belongs to the label Positie
  //TLDR: Ruimte->Room, Nummer->Position, Positie->Placement
  const formData = ref({
    qrCode: '',
    room: '',
    isRepair: false,
    isExternal: false,
    placement: '',
    position: '',
    fireResistantRequirement: '',
    editedBy: '',
    editedByUid: '',
    editedDate: '',
    status: '',
    statusIndex: 0,
    description: '',
    category: 0,
    remarks: '',
    floorID: '',
    imagesPending: [],
    imagesReplacePending: [],
    imagesDeletePending: [],
    images: [],
    materials: [],
    _imagesCount: 0,
    componentToAdd: {
      selectedComponent: {
        componentID: '',
        componentName: '',
      },
      selectedType: {
        typeName: '',
        typeID: '',
      },
      selectedAmount: '',
      insulation: '',
    },
    addedComponents: [],
    availableComponents: [],
    addedDeletedComponents: [],
    componentsToDelete: [],
  });

  //Check if value of status is 'Goedgekeurd' this changes the validation
  const isStatusApproved = computed(() =>
    selected.value.id === 2 ? true : false
  );

  getAuth().onAuthStateChanged((u) => {
    user.value = u;

    if (user.value) {
      u.getIdTokenResult().then(function ({ claims }) {
        role.value = claims.role;
      });
    }
  });
  watch(
    () => props.marker,
    async (newValue) => {
      if (newValue.createdDate) {
        isLoadingData.value = true;

        const promises = [
          getMarkerComponents(),
          getMarkersMaterials(),
          getFloors(),
          setImagesByProjectID(props.projectID),
          createFireStoreReferences(),
          openEditMarker(),
          getImagesOnChange(),
        ];
        await Promise.allSettled(promises);
        isLoadingData.value = false;
      }
    }
  );

  async function createFireStoreReferences() {
    markerMaterialsCollection.value = collection(
      db,
      'projects',
      props.projectID,
      'floors',
      props.floorID,
      'markers',
      props.marker.id,
      'materials'
    );

    imagesCollection.value = collection(
      db,
      'projects',
      props.projectID,
      'floors',
      props.floorID,
      'markers',
      props.marker.id,
      'images'
    );

    markerComponentsCollection.value = collection(
      db,
      'projects',
      props.projectID,
      'floors',
      props.floorID,
      'markers',
      props.marker.id,
      'components'
    );

    markerRef.value = doc(
      db,
      'projects',
      props.projectID,
      'floors',
      props.floorID,
      'markers',
      props.marker.id
    );

    historyCollection.value = collection(
      db,
      'projects',
      props.projectID,
      'floors',
      props.floorID,
      'markers',
      props.marker.id,
      'history'
    );

    return true;
  }

  async function openEditMarker() {
    //Setting functionality fields
    selected.value = MarkerStatuses[props.marker.statusIndex];
    currentStatus.value = props.marker.status;

    //Setting form data
    formData.value.qrCode = props.marker.qrCode;
    formData.value.room = props.marker.room;
    formData.value.placement = props.marker.placement;
    formData.value.position = props.marker.position;
    formData.value.insulation = props.marker.insulation;
    if (props.marker.category) {
      formData.value.category = props.marker.category;
    }
    formData.value.fireResistantRequirement =
      props.marker.fireResistantRequirement;
    formData.value.status = props.marker.status;
    formData.value.statusIndex = props.marker.statusIndex;
    formData.value.description = props.marker.description;
    formData.value.editedBy = props.marker.editedBy;
    formData.value.editedByUid = props.marker.editedByUid;
    formData.value.editedDate = props.marker.editedDate;
    formData.value.remarks = props.marker.remarks;
    formData.value._imagesCount = props.marker._imagesCount;
    formData.value.floorID = props.floorID;
    formData.value.isExternal = props.marker.isExternal;
    formData.value.isRepair = props.marker.isRepair;

    //clearing temp data
    formData.value.imagesPending = [];
    formData.value.imagesDeletePending = [];

    return true;
  }

  async function getImages() {
    const q = query(
      imagesCollection.value,
      orderBy('positionNumber'),
      orderBy('timeStampAdded')
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot;
  }

  async function getImagesOnChange() {
    const q = query(
      imagesCollection.value,
      orderBy('positionNumber'),
      orderBy('timeStampAdded')
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      formData.value.images.push({ ...doc.data(), id: doc.id });
    });

    return true;
  }

  async function getMaterialsDocs() {
    return getDocs(markerMaterialsCollection.value);
  }

  async function getHistoryDocs() {
    return getDocs(historyCollection.value);
  }

  function until(conditionFunction) {
    const poll = (resolve) => {
      if (conditionFunction()) resolve();
      else setTimeout((_) => poll(resolve), 400);
    };

    return new Promise(poll);
  }

  async function getMarkersMaterials() {
    await until((_) => isLoadingDyanmicData.value === false);

    //copy over all materials without reference
    const availableMaterials = allMaterials.value.map((object) => ({
      ...object,
    }));
    const selectedMaterials = [];

    const snapshot = await getMaterialsDocs();
    snapshot.forEach((doc) => {
      const newMaterialEntry = {
        selected: true,
        data: doc,
      };
      selectedMaterials.push(newMaterialEntry);
    });

    for (const selectedMaterial of selectedMaterials) {
      const foundIndex = availableMaterials.findIndex(
        (material) => material.data.id === selectedMaterial.data.id
      );

      //findIndex() returns -1 if it doesn't find a match
      if (foundIndex !== -1) {
        availableMaterials[foundIndex].selected = true;
      } else {
        selectedMaterial.isRemoved = true;
        availableMaterials.push(selectedMaterial);
      }
    }

    //filter not active types out
    var newNewArray = availableMaterials.filter(function (el) {
      return (
        el.data.data().materialStatus === 'Actief' || el.isRemoved === true
      );
    });

    formData.value.materials = newNewArray;
    return true;
  }

  async function getFloors() {
    const q = query(floorsCollection.value, orderBy('floorNumber'));
    const querySnapshotFloors = await getDocs(q);
    allFloors.value = querySnapshotFloors.docs;
    formData.value.floorID = allFloors.value.find(
      (floor) => floor.id === props.floorID
    ).id;
  }

  async function getComponentsDocs() {
    return getDocs(markerComponentsCollection.value);
  }

  async function getMarkerComponents() {
    await until((_) => isLoadingDyanmicData.value === false);
    const newAddedComponentEntry = ref();

    const snapshotAddedComponents = await getComponentsDocs();

    snapshotAddedComponents.forEach((doc) => {
      newAddedComponentEntry.value = {
        componentName: doc.data().componentName,
        componentID: doc.data().componentID,
        typeName: doc.data().typeName,
        typeID: doc.data().typeID,
        selectedAmount: doc.data().amount,
        dataComponent: doc,
        insulation: doc.data().insulation,
      };

      var foundIndexAll = allComponents.value.findIndex(
        (localComponent) =>
          localComponent.componentID === doc.data().componentID
      );
      var foundIndexActive = activeComponents.value.findIndex(
        (localComponent) =>
          localComponent.componentID === doc.data().componentID
      );

      //if findINdex is not -1 there is a match so add it to the addedComponents list
      //If there is no match that means the component was removed and should be added to the removed components list
      if (foundIndexAll !== -1) {
        if (foundIndexActive !== -1) {
          if (
            activeComponents.value[foundIndexActive].types.findIndex(
              (localType) => localType.typeID === doc.data().typeID
            ) !== -1
          ) {
            formData.value.addedComponents.push(newAddedComponentEntry.value);
          } else {
            if (
              allComponents.value[foundIndexAll].types.findIndex(
                (localType) => localType.typeID === doc.data().typeID
              ) === -1
            ) {
              formData.value.addedDeletedComponents.push(
                newAddedComponentEntry.value
              );
            }
          }
        }
      } else {
        formData.value.addedDeletedComponents.push(
          newAddedComponentEntry.value
        );
      }
    });
    return true;
  }

  //Submit for saving the marker
  //The awaits are ignored offline because the promises won't resolve
  //Floor change should never be allowed to do offline -> the input field for it is hidden offline
  async function submit() {
  try {
    //Resets form errors and starts validation
    formError.value = '';
    let validationError = false;
    
    const statusName = MarkerStatuses.find((status) => status.id === selected.value.id).name;
    let totalImages = formData.value.images.length + formData.value.imagesPending.length;
    let totalMaterials = formData.value.materials.filter((mat) => mat.selected === true).length;
    
    // Image validation for approved status
    if (selected.value.id === 2 && totalImages < 2) {
      formError.value = `Een spot met de status '${statusName}' dient ten minste twee afbeeldingen te hebben geüpload.`;
      validationError = true;
    // Image validation for opname status
    } else if (selected.value.id === 3 && totalImages < 1) {
      formError.value = `Een spot met de status '${statusName}' dient ten minste één afbeelding te hebben geüpload.`;
      validationError = true;
    }
    
    // Component validation for approved status
    if (selected.value.id === 2 && formData.value.addedComponents.length < 1) {
      formError.value = `Een spot met de status '${statusName}' moet ten minste één componentoptie bevatten.`;
      validationError = true;
    }
    
    // Material validation based on isExternal and isRepair
    if (!formData.value.isExternal && formData.value.isRepair && selected.value.id !== 3) {
      // At least one material required for repair
      if (totalMaterials < 1) {
        formError.value = `Een spot met de status '${statusName}' en 'Betreft reparatie' moet ten minste één materiaaloptie bevatten.`;
        validationError = true;
      }
    } else if (!formData.value.isExternal && !formData.value.isRepair && selected.value.id !== 3) {
      // At least one material required for normal spots
      if (totalMaterials < 1) {
        formError.value = `Een spot met de status '${statusName}' moet ten minste één materiaaloptie bevatten.`;
        validationError = true;
      }
    }
    // If isExternal is true, no materials are required regardless of isRepair

    if (!validationError) {
      loading.value = true;

      //Creating booleans to be used throughout the editMarker() function
      const isFloorChanged = formData.value.floorID !== props.floorID;
      const isLegacyMarker = props.marker.isLegacy;

      // Marker object to be uploaded
      var postMarker = {
        ...props.marker,
        qrCode: formData.value.qrCode,
        room: formData.value.room,
        placement: formData.value.placement,
        position: formData.value.position,
        fireResistantRequirement: formData.value.fireResistantRequirement,
        status: selected.value.name,
        statusIndex: selected.value.id,
        description: formData.value.description,
        category: formData.value.category,
        editedBy: user.value.displayName,
        editedByUid: user.value.uid,
        editedDate: moment().format('DD-MM-YYYY HH:mm'),
        timeStampLastWorkedOn: new Date(),
        remarks: formData.value.remarks,
        floorID: formData.value.floorID,
        isExternal: formData.value.isExternal === undefined ? false : formData.value.isExternal,
        isRepair: formData.value.isRepair === undefined ? false : formData.value.isRepair,
        _imagesCount: increment(formData.value.imagesPending.length),
      };

      delete postMarker.imageDownloadURL;

      //If the marker is a legacy marker (imported from the original database), we update the long, lat and isLegacy bool
      if (isLegacyMarker) {
        postMarker.isLegacy = false;
        postMarker.longitude = props.marker.longitude;
        postMarker.latitude = props.marker.latitude;
      }

      //Updates the history when there is a status change
      if (currentStatus.value !== postMarker.status) {
        connectionStatusStore.online
          ? await updateHistory(selected.value.name, selected.value.id, props.floorID)
          : updateHistory(selected.value.name, selected.value.id, props.floorID);
      }

      //If the floor was changed we reset the lat & long and update the status to a one-time status 'Verplaatst' with index 5
      if (isFloorChanged) {
        postMarker.latitude = 0;
        postMarker.longitude = 0;
        postMarker.status = 'Verplaatst van etage ' + allFloors.value.find((floor) => floor.id === props.floorID).data().floorName;
        postMarker.statusIndex = 5;
      }

      //Creates the firebase reference for uploading the new marker to the correct floor
      if (isFloorChanged) {
        var changedFloorMarkerRef = doc(db, 'projects', props.projectID, 'floors', formData.value.floorID, 'markers', props.marker.id);
      }

      //If the floor changed we setDoc to create the new marker with a different ref otherwise we just default to updating the current marker with updateDoc
      if (connectionStatusStore.online) {
        isFloorChanged
          ? await setDoc(changedFloorMarkerRef, postMarker)
          : await updateDoc(markerRef.value, postMarker);
      } else {
        isFloorChanged
          ? setDoc(changedFloorMarkerRef, postMarker)
          : updateDoc(markerRef.value, postMarker);
      }

      //Checks if images were deleted.
      //If we are online we deleted them
      //If we are offline we add them to the IDB as queued for deletion (the actual deletion happens when the 'upload' functionality is used)
      if (formData.value.imagesPending.length > 0) {
        connectionStatusStore.online
          ? await uploadImages()
          : await saveImagesOffline();
      }

      //Checks if images were replaced
      //If we are online we deleted them
      //If we are offline we add them to the IDB as queued for deletion (the actual deletion happens when the 'upload' functionality is used)
      if (formData.value.imagesReplacePending.length > 0) {
        connectionStatusStore.online
          ? await uploadReplaceImages()
          : await saveImagesOffline();
      }

      //Checks if images were deleted.
      //If we are online we deleted them
      //If we are offline we add them to the IDB as queued for deletion (the actual deletion happens when the 'upload' functionality is used)
      if (formData.value.imagesDeletePending.length > 0) {
        for (const image of formData.value.imagesDeletePending) {
          if (!connectionStatusStore.online) {
            await imageDeleteOffline(image);
          } else {
            //Deleted the image from the storage
            const fullPath = 'markers/' + image.imageStorageID;
            const deleteRef = firebaseRef(storage, fullPath);
            await deleteObject(deleteRef);
          }
          //Deletes the nested doc reference in the images list
          const imageRef = doc(db, 'projects', props.projectID, 'floors', props.floorID, 'markers', props.marker.id, 'images', image.id);

          connectionStatusStore.online
            ? await deleteDoc(imageRef)
            : deleteDoc(imageRef);
        }
      }

      //Checks the materials and deletes/creates them
      for (const material of formData.value.materials) {
        const newMaterialEntry = {
          materialID: material.data.id,
        };

        if (material.selected) {
          connectionStatusStore.online
            ? await setDoc(doc(markerMaterialsCollection.value, material.data.id), newMaterialEntry)
            : setDoc(doc(markerMaterialsCollection.value, material.data.id), newMaterialEntry);
        } else {
          connectionStatusStore.online
            ? await deleteDoc(doc(markerMaterialsCollection.value, material.data.id))
            : deleteDoc(doc(markerMaterialsCollection.value, material.data.id));
        }
      }

      //Checks the components and deletes/creates them
      const allComponents = formData.value.addedComponents.concat(formData.value.addedDeletedComponents);

      for (const newComponent of allComponents) {
        const newComponentEntry = {
          componentID: newComponent.componentID,
          typeID: newComponent.typeID,
          amount: newComponent.selectedAmount,
          insulation: newComponent.insulation,
        };

        var uuid = 0;

        newComponent.localOnly
          ? (uuid = uuidv4())
          : (uuid = newComponent.dataComponent.id);

        connectionStatusStore.online
          ? await setDoc(doc(markerComponentsCollection.value, uuid), newComponentEntry)
          : setDoc(doc(markerComponentsCollection.value, uuid), newComponentEntry);
      }

      for (const deleteComponent of formData.value.componentsToDelete) {
        connectionStatusStore.online
          ? await deleteDoc(doc(markerComponentsCollection.value, deleteComponent.dataComponent.id))
          : deleteDoc(doc(markerComponentsCollection.value, deleteComponent.dataComponent.id));
      }

      //Updating the last worked on date on the project
      const projectsRef = doc(db, 'projects', props.projectID);

      const project = {
        lastWorkedOnDate: moment().format('DD-MM-YYYY HH:mm'),
        timeStampLastWorkedOn: new Date(),
      };

      connectionStatusStore.online
        ? await updateDoc(projectsRef, project)
        : updateDoc(projectsRef, project);

      if (isFloorChanged) {
        //Gets image docs, uploads them to the new marker and deletes them from the original marker
        const newFloorID = formData.value.floorID;
        //Retrieval of images
        const imagesResult = await getImages();

        for (var image of imagesResult.docs) {
          //Saving of images
          await setDoc(createNestedReference(newFloorID, 'images', image.id), image.data());

          //Removal of images
          await deleteDoc(createNestedReference(props.floorID, 'images', image.id));
        }

        //Gets materials docs, uploads them to the new marker and deletes them from the original marker
        const materialsResult = await getMaterialsDocs();

        for (var material of materialsResult.docs) {
          //Saving of materials
          await setDoc(createNestedReference(newFloorID, 'materials', material.id), material.data());

          //Removal of materials
          await deleteDoc(createNestedReference(props.floorID, 'materials', material.id));
        }

        //Gets components docs, uploads them to the new marker and deletes them from the original marker
        const componentsResult = await getComponentsDocs();

        for (var component of componentsResult.docs) {
          //Saving of components
          await setDoc(createNestedReference(newFloorID, 'components', component.id), component.data());

          //Removal of components
          await deleteDoc(createNestedReference(props.floorID, 'components', component.id));
        }

        //Gets components docs, uploads them to the new marker and deletes them from the original marker
        const historyResults = await getHistoryDocs();

        for (var history of historyResults.docs) {
          //Saving of components
          await setDoc(createNestedReference(newFloorID, 'history', history.id), history.data());

          //Removal of components
          await deleteDoc(createNestedReference(props.floorID, 'history', history.id));
        }

        //Added an history entry for moving the marker
        await updateHistory(
          'Verplaatst van etage ' + allFloors.value.find((floor) => floor.id === props.floorID).data().floorName,
          5,
          newFloorID
        );

        //Updating the amount of markers on the new floor
        const newFloorRef = doc(db, 'projects', props.projectID, 'floors', formData.value.floorID);

        const newFloor = {
          amountOfMarkers: increment(1),
        };

        await updateDoc(newFloorRef, newFloor);

        //Updating the amount of markers on the old floor
        const floorRef = doc(db, 'projects', props.projectID, 'floors', props.floorID);

        const floor = {
          amountOfMarkers: increment(-1),
        };

        await updateDoc(floorRef, floor);

        //Finally deleting the original marker
        await deleteDoc(markerRef.value);
      }
    }
  } catch (e) {
    console.log(e);
    formError.value = 'Er is iets misgegaan';
  } finally {
    loading.value = false;
    if (!formError.value) {
      closeEditModal();
    }
  }
}

  async function saveImagesOffline() {
    const savedMarkerID = props.marker.id;
    for (const image of formData.value.imagesPending) {
      await saveImageOffline(image, savedMarkerID, false);
    }
    for (const image of formData.value.imagesReplacePending) {
      await saveImageOffline(image, savedMarkerID, true);
    }
  }

  async function saveImageOffline(image, markerID, isReplace) {
    return new Promise((resolve, reject) => {
      let retrievedImage;
      if (isReplace) {
        retrievedImage = formData.value.images[image.index];
      }
      //Storage reference to image
      const uuidStorageImage = uuidv4();
      const uuidFirestoreImage = uuidv4();

      //Blob to base64 voor local storage of the image
      blobToBase64(image.imageData).then(async function (base64String) {
        await useMarkersStore().saveImage({
          id: isReplace ? retrievedImage.id : uuidFirestoreImage,
          markerID: markerID,
          floorID: props.floorID,
          projectID: props.projectID,
          imageStorageID: isReplace
            ? retrievedImage.imageStorageID
            : uuidStorageImage,
          imageID: isReplace ? retrievedImage.id : uuidFirestoreImage,
          uploaded: false,
          deletePending: false,
          base64: base64String,
          storageLocation: 'markers/',
        });
        resolve(true);
      });

      if (isReplace) {
        const newImageEntry = {
          imageStorageID: retrievedImage.imageStorageID,
          imageDownloadURL: '',
          uploadedDate: moment().format('DD-MM-YYYY HH:mm'),
          timeStampAdded: new Date(),
        };
        updateDoc(
          doc(imagesCollection.value, retrievedImage.id),
          newImageEntry
        );
      } else {
        const newImageEntry = {
          imageStorageID: uuidStorageImage,
          imageDownloadURL: '',
          uploadedDate: moment().format('DD-MM-YYYY HH:mm'),
          positionNumber: image.positionNumber,
          timeStampAdded: new Date(),
        };

        setDoc(doc(imagesCollection.value, uuidFirestoreImage), newImageEntry)
          .then(() => {})
          .catch((error) => {
            formError.value = 'Error';
          });
      }
    });
  }

  async function uploadImages() {
    for (const image of formData.value.imagesPending) {
      const uuid = uuidv4();
      const storageRef = firebaseRef(storage, 'markers/' + uuid);

      const uploadTask = uploadBytesResumable(storageRef, image.imageData);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          switch (snapshot.state) {
            case 'paused':
              break;
            case 'running':
              break;
          }
        },
        (error) => {},
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const newImageEntry = {
              imageStorageID: uuid,
              imageDownloadURL: downloadURL,
              uploadedDate: moment().format('DD-MM-YYYY HH:mm'),
              positionNumber: image.positionNumber,
              timeStampAdded: new Date(),
            };

            addDoc(imagesCollection.value, newImageEntry);
          });
        }
      );
      await uploadTask;
    }
    return true;
  }

  async function uploadReplaceImages() {
    for (const image of formData.value.imagesReplacePending) {
      const retrievedImage = formData.value.images[image.index];
      const storageRef = firebaseRef(
        storage,
        'markers/' + retrievedImage.imageStorageID
      );

      const uploadTask = uploadBytesResumable(storageRef, image.imageData);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          switch (snapshot.state) {
            case 'paused':
              break;
            case 'running':
              break;
          }
        },
        (error) => {},
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const newImageEntry = {
              imageStorageID: retrievedImage.imageStorageID,
              imageDownloadURL: downloadURL,
              uploadedDate: moment().format('DD-MM-YYYY HH:mm'),
              timeStampAdded: new Date(),
            };
            updateDoc(
              doc(imagesCollection.value, retrievedImage.id),
              newImageEntry
            );
          });
        }
      );
      await uploadTask;
    }
    return true;
  }

  async function updateHistory(statusName, statusIndex, floorID) {
    const newHistoryEntry = {
      status: statusName,
      statusIndex: statusIndex,
      editedBy: user.value.displayName,
      editedByUid: user.value.uid,
      editedDate: moment().format('DD-MM-YYYY HH:mm'),
      timeStampAdded: new Date(),
    };

    return addDoc(createNestedCollection(floorID, 'history'), newHistoryEntry);
  }

  async function imageDeleteOffline(image) {
    return useMarkersStore().updateImageDeletePending(
      image,
      props.marker.id,
      props.floorID,
      props.projectID
    );
  }

  function closeEditModal() {
    setTimeout(() => {
      formData.value = {
        name: '',
        qrCode: '',
        isRepair: false,
        isExternal: false,
        room: '',
        placement: '',
        position: '',
        fireResistantRequirement: '',
        editedBy: '',
        editedByUid: '',
        editedDate: '',
        status: '',
        statusIndex: 0,
        category: 0,
        description: '',
        remarks: '',
        floorID: '',
        imagesPending: [],
        imagesDeletePending: [],
        imagesReplacePending: [],
        images: [],
        materials: [],
        _imagesCount: 0,
        componentToAdd: {
          selectedComponent: {
            componentID: '',
            componentName: '',
          },
          selectedType: {
            typeName: '',
            typeID: '',
          },
          selectedAmount: '',
          insulation: '',
        },
        addedComponents: [],
        availableComponents: [],
        componentsToDelete: [],
        addedDeletedComponents: [],
      };
      formError.value = '';
    }, 200);

    emit('closeEditModal');
  }

  function openImageModal(imageSrc) {
    imageModalOpen.value = true;
    imageModalCurrentSrc.value = imageSrc;
  }

  async function addType() {
    try {
      //Sets loading state for adding a type
      loadingTypeSave.value = true;

      //Generate ID to be used for the type (Needed for offline functionality)
      const generatedID = uuidv4();

      const typesCollection = collection(
        db,
        'components',
        formData.value.componentToAdd.selectedComponent.componentID,
        'types'
      );

      //Creates type object to be uploaded
      let type = {
        typeName: typeFilter.value,
        typeStatus: 'Actief',
        typeNumber: '',
      };

      //Saves new type under the selected component
      connectionStatusStore.online
        ? await setDoc(doc(typesCollection, generatedID), type)
        : setDoc(doc(typesCollection, generatedID), type);

      const componentRef = doc(
        db,
        'components',
        formData.value.componentToAdd.selectedComponent.componentID
      );

      const component = {
        amountOfTypes: increment(1),
      };

      //Updates the amount of types under the selected component
      connectionStatusStore.online
        ? await updateDoc(componentRef, component)
        : updateDoc(componentRef, component);

      //Adds the type locally so it can instantly be used
      type.typeID = generatedID;

      var foundComp = activeComponents.value.find(
        (el) =>
          el.componentID ===
          formData.value.componentToAdd.selectedComponent.componentID
      );
      if (foundComp) {
        foundComp.types.push(type);
      }
    } catch (e) {
      console.log(e);
      formError.value =
        'Er is iets misgegaan bij het toevoegen van een nieuw type';
    } finally {
      loadingTypeSave.value = false;
    }
  }

  //This is a helper function to dynamically create nested references. (used for images, history, materials and components)
  function createNestedReference(floorID, collection, docID) {
    return doc(
      db,
      'projects',
      props.projectID,
      'floors',
      floorID,
      'markers',
      props.marker.id,
      collection,
      docID
    );
  }

  //This is a helper function to dynamically create nested colletions. (used for images, history, materials and components)
  function createNestedCollection(floorID, collectionName) {
    return collection(
      db,
      'projects',
      props.projectID,
      'floors',
      floorID,
      'markers',
      props.marker.id,
      collectionName
    );
  }

  function removeAddedComponent(index, isRemoved) {
    if (isRemoved) {
      formData.value.componentsToDelete.push(
        formData.value.addedDeletedComponents[index]
      );
      formData.value.addedDeletedComponents.splice(index, 1);
    } else {
      if (!formData.value.addedComponents[index].localOnly) {
        formData.value.componentsToDelete.push(
          formData.value.addedComponents[index]
        );
      }
      formData.value.addedComponents.splice(index, 1);
    }
  }

  function clearAddNewComponentType() {
    formData.value.componentToAdd.selectedType = '';
    formData.value.componentToAdd.selectedAmount = '';
    formData.value.componentToAdd.insulation = 'Geen';
  }

  function addNewComponentType() {
    const newComponent = {
      componentName:
        formData.value.componentToAdd.selectedComponent.componentName,
      componentID: formData.value.componentToAdd.selectedComponent.componentID,
      typeName: formData.value.componentToAdd.selectedType.typeName,
      typeID: formData.value.componentToAdd.selectedType.typeID,
      selectedAmount: formData.value.componentToAdd.selectedAmount,
      insulation: formData.value.componentToAdd.insulation,
      localOnly: true,
    };

    formData.value.addedComponents.push(newComponent);
    formData.value.componentToAdd.selectedAmount = '';
  }

  function addNewImageToPendingList(url, data, index) {
    const newImage = {
      imageSrc: url,
      imageData: data,
      positionNumber: Number(formData.value._imagesCount),
      index: index,
    };

    //If there is a index this means we are replacing an existing image
    if (!isNaN(index)) {
      formData.value.images[index].imageDownloadURL = url;

      const i = formData.value.imagesReplacePending.findIndex(
        (img) => img.index === index
      );

      newImage.id = formData.value.images[index].id;

      if (i > -1) {
        formData.value.imagesReplacePending[i] = newImage;
      } else {
        formData.value.imagesReplacePending.push(newImage);
      }
    } else {
      formData.value.imagesPending.push(newImage);
      formData.value._imagesCount++;
    }
  }

  function rotateImage(index, imageID) {
    let img = new Image();
    img.crossOrigin = 'anonymous'; // Allow cross-origin requests

    if (connectionStatusStore.online) {
      img.src = formData.value.images.find(
        (rotateImage) => rotateImage.id === imageID
      ).imageDownloadURL;
    } else {
      let replaceImage = formData.value.imagesReplacePending.find(
        (rotateImage) => rotateImage.id === imageID
      );
      if (replaceImage) {
        img.src = replaceImage.imageSrc;
      } else {
        let localImage = localImages.value.find(
          (rotateImage) => rotateImage.id === imageID
        );
        if (localImage) {
          img.src = localImage.base64;
        }
      }
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    img.onload = () => {
      canvas.width = img.height;
      canvas.height = img.width;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((90 * Math.PI) / 180);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
      canvas.toBlob((blob) => {
        let rotatedImage = blob;
        let downloadUrl = URL.createObjectURL(blob);
        addNewImageToPendingList(downloadUrl, rotatedImage, index);
      }, 'image/jpeg');
    };
  }

  function deleteImage(index, imageToBeDeleted) {
    const i = formData.value.imagesReplacePending.findIndex(
      (img) => img.index === index
    );

    if (i > -1) {
      formData.value.imagesReplacePending.splice(index, 1);
    }

    formData.value.imagesDeletePending.push(imageToBeDeleted);
    formData.value.images.splice(index, 1);
  }

  function removeImagePending(index) {
    formData.value.imagesPending.splice(index, 1);
  }

  function handleSnapshot({ file, index }) {
    // Create a simulated event object with the file
    const event = {
      target: {
        files: [file],
      },
    };

    // Call the onFileChange function with the event and the index
    onFileChange(event, index);
  }

  function onFileChange(e, index) {
    const files = [...(e.target.files || e.dataTransfer.files)];
    if (!files.length) return;

    compress.value
      .compress(files, {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1,
        maxWidth: 1600, // the max width of the output image, defaults to 1920px
        maxHeight: 1200, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
      })
      .then((results) => {
        // returns an array of compressed images
        const img1 = results[0];
        const base64str = img1.data;
        const imgExt = img1.ext;
        const file = Compress.convertBase64ToFile(base64str, imgExt);
        const imgSrcUrl1 = URL.createObjectURL(file);
        addNewImageToPendingList(imgSrcUrl1, file, index);
      });
  }

  function dragleave(e) {
    e.currentTarget.classList.add('border-gray-300');
    e.currentTarget.classList.remove('border-blue-300');
  }

  function dragover(e) {
    e.preventDefault();
    if (!e.currentTarget.classList.contains('border-blue-300')) {
      e.currentTarget.classList.remove('border-gray-300');
      e.currentTarget.classList.add('border-blue-300');
    }
  }

  function drop(e, index) {
    e.preventDefault();
    onFileChange(e, index);
    // Clean up
    e.currentTarget.classList.add('border-gray-300');
    e.currentTarget.classList.remove('border-blue-300');
  }

  function materialOnChange(material) {
    if (!material.selected) {
      if (material.isRemoved) {
        currentMaterialConfirmation.value = material;
        confirmModalData.value.title =
          material.data.data().materialName + ' deselecteren';
        confirmModalData.value.body =
          'Let op, het gaat hier om een materiaal dat verwijderd is. Bij het deselecteren zal deze permanent van deze spot verwijderd worden nadat de spot is opgeslagen.';
        confirmModalData.value.open = true;
      }
    }
  }

  function onCancelDelete() {
    currentMaterialConfirmation.value.selected = true;
    confirmModalData.value.open = false;
  }

  function onConfirmDelete() {
    confirmModalData.value.open = false;
  }

  function QRScanComplete(QRCode) {
    formData.value.qrCode = QRCode;

    QRScanModalOpen.value = false;
  }

  function openCamera(index) {
    cameraModalOpen.value = true;
    selectedImageIndex.value = index;
  }

  function closeCameraModal() {
    cameraModalOpen.value = false;
  }

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };
</script>
