export const Categories = [
    {
        id: 0,
        name: 'Doorvoeringen/sparingen'
    },
    {
        id: 1,
        name: 'Staalconstructie bekleden / coaten'
    },
    {
        id: 2,
        name: 'Bouwkundig'
    }
];
