<template>
  <div
    v-if="open"
    class="fixed inset-0 z-20 overflow-y-auto bg-black overflow-hidden"
  >
    <div class="relative w-full h-full transition flex justify-center">
      <div class="w-full md:w-3/4">
        <Camera
          :resolution="{ width: 1600, height: 1600 }"
          @started="onLoading"
          @error="onError"
          ref="camera"
          autoplay
        >
        </Camera>
      </div>

      <div>
        <div class="absolute z-20 right-5 top-5 flex-col">
          <div class="flex items-center justify-end space-x-8">
            <!-- <select
              :disabled="isLoading || !isReady"
              v-if="devices.length"
              name="cameras"
              id="cameras"
              v-model="currentDevice"
              @change="switchCamera"
              class="border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:opacity-70"
            >
              <option v-for="value of devices" :value="value.deviceId">
                {{ value.label }}
              </option>
            </select> -->

            <button
              :disabled="isLoading || !isReady"
              type="button"
              class="disabled:opacity-70"
              @click="closeModal"
            >
              <XIcon class="w-8 text-white"></XIcon>
            </button>
          </div>
        </div>
      </div>

      <button
        @click="toggleTorch()"
        :disabled="!torchSupported || isLoadingTorch"
        class="p-3 rounded-md bg-primary absolute top-8 -translate-y-1/3 left-8 disabled:opacity-70"
      >
        <div
          class="w-5 h-0.5 bg-red-500 transform rotate-45 origin-center absolute left-0 top-0 z-10 translate-y-[22px] translate-x-[12px]"
          v-if="!torchSupported"
        ></div>
        <LightningBoltIcon
          class="flex-shrink-0 w-5 h-5"
          :class="torchSupported ? 'text-black' : 'text-red-500'"
        />
      </button>

      <div
        class="absolute bottom-8 left-1/2 -translate-x-1/2 z-10 landscape:right-8 landscape:left-auto landscape:bottom-1/2 landscape:translate-y-1/2"
      >
        <button
          :disabled="isLoading || !isReady || isLoadingTorch"
          @click="snapshot"
          type="button"
          class="disabled:opacity-70"
        >
          <div
            class="rounded-full border-2 w-16 aspect-square flex items-center justify-center"
          >
            <div class="w-8 bg-primary rounded-full aspect-square"></div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import Camera from 'simple-vue-camera';
  import { XIcon } from '@heroicons/vue/outline';
  import { createToaster } from '@meforma/vue-toaster';
  import { LightningBoltIcon } from '@heroicons/vue/outline';

  const camera = ref();
  const isLoading = ref(false);
  const selectedCamera = ref();
  const devices = ref([]);
  const currentDevice = ref(null);
  const torchSupported = ref(false);
  const currentTrack = ref(null);
  const isLoadingTorch = ref(false);
  const torchState = ref(false); // New ref to store torch state

  const toaster = createToaster({
    duration: 7200,

    type: 'error',

    position: 'top',
  });

  const isReady = ref(false);

  const props = defineProps({
    open: {
      type: Boolean,

      required: true,

      default: () => false,
    },

    index: {
      type: Number,

      required: false,

      default: () => undefined,
    },
  });

  // Use camera reference to call functions

  const snapshot = async () => {
    try {
      isLoading.value = true;

      const blob = await camera.value?.snapshot();

      const timestamp = new Date().toISOString();

      // Create a File object from the blob

      const file = new File([blob], `snapshot_${timestamp}.jpg`, {
        type: blob.type,
      });

      // Emit the File object

      emit('on-snapshot', { file, index: props.index });

      emit('close-modal');
    } catch (e) {
      toaster.show(e);
    } finally {
      isLoading.value = false;
    }
  };

  const switchCamera = () => {
    camera.value?.changeCamera(selectedCamera.value.deviceId);
  };

  const onLoading = async () => {
    isReady.value = true;

    try {
      devices.value = await camera.value?.devices(['videoinput']);
      // currentDevice.value = camera.value.currentDeviceID();
      torchSupported.value = isTorchSupported();

      // Load and apply the torch state from local storage
      const savedTorchState = JSON.parse(localStorage.getItem('torchState'));
      if (savedTorchState && torchSupported.value) {
        await toggleTorch(); // This will apply the saved state
      }
    } catch (e) {
      toaster.show(e);
    }
  };

  const onError = (e) => {
    toaster.show(e);
  };

  const emit = defineEmits(['close-modal', 'on-snapshot']);

  function closeModal() {
    emit('close-modal');
  }

  function isTorchSupported() {
    // Access the video element directly using the camera ref
    const stream = camera.value?.stream;

    if (stream) {
      // Get the first video track from the stream
      const track = stream.getVideoTracks()[0];

      if (track) {
        currentTrack.value = track;
        const settings = track.getSettings();
        // Check if 'torch' is a property in the track settings
        return 'torch' in settings;
      }
    }
    return false;
  }

  // Save torch state to localStorage
  function saveTorchState(state) {
    localStorage.setItem('torchState', JSON.stringify(state));
  }
  async function toggleTorch() {
    if (currentTrack.value) {
      isLoadingTorch.value = true;

      // Get the current torch state
      const settings = currentTrack.value.getSettings();
      const currentTorchState = settings.torch || false;
      const newTorchState = !currentTorchState;

      // Toggle the torch state
      try {
        await currentTrack.value.applyConstraints({
          advanced: [{ torch: newTorchState }],
        });
        torchState.value = newTorchState;
        saveTorchState(newTorchState); // Save new state
      } catch (e) {
        console.error('Error toggling torch:', e);
      } finally {
        isLoadingTorch.value = false;
      }
    }
  }
</script>
